import React, { useEffect } from 'react';
import {BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import Home from './app/components/home';
import PowerForAll from './app/components/powerforall';
import ReactGA from 'react-ga';


function App() {
  useEffect(() => {
    ReactGA.initialize('G-6ZD5XZV4R0');
  },[]);
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/powerforall" component={PowerForAll}/>
          <Route component={Home}/>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
