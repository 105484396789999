import React ,{useEffect, useState} from 'react';
import { CSSTransitionGroup } from 'react-transition-group'

import { useMediaQuery } from 'react-responsive'
import '../../styles/index.css';
import boxMainImmage from '../../images/new_images/front-direct-image-1.png';
import boxSidePotraite from '../../images/side-potrait.png';
import boxFront from '../../images/front-strieght.png';
import boxFrontSided from '../../images/front-sided.png';
// import boxBack from '../../images/back-striaght.png';
// import boxBackSided from '../../images/back-sided.png';
import boxBackSided from '../../images/new_images/back-side.png';
import boxLoadSupport from '../../images/new_images/load_support.png';
import boxBackSidedNew from '../../images/new_images/back_sided-new.png';
import boxSided from '../../images/new_images/sided_new.png';

import lhgp_Logo from '../../images/new_images/lhgp_logo.jpeg';
import afritech_Logo from '../../images/new_images/afritech_logo.png';



import boxFrontFacingPowerBtn from '../../images/new_images/front_sided_power_btn_focus.png';
import Icons from '../icons';
import ReactGA from 'react-ga';







export function MainHeader(){
    return(
        <div className="power_for_all_header_wrapper_mobile" style={{display:'flex',flexDirection:'column'}}>
            <div style={{width:'100%',backgroundColor:'rgba(0,0,0,0.7)',flex:1,minHeight:500,display:'flex',justifyContent:'center',alignItems:'center'}}>

                    <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>


                        <div style={{padding:'10px 10px 30px'}}>
                            <div style={{fontSize:'50px',fontFamily:"'Roboto', sans-serif",fontWeight:200,color:'#FFFFFF',opacity:0.95}}>
                                Power For All
                            </div>
                        </div>


                        <div style={{padding:'20px 10px 30px'}}>
                            <div style={{fontSize:'20px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#FFFFFF',marginLeft:25,opacity:0.95}}>
                                50 primary health centers accross Nigeria 
                                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria
                                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria
                            </div>
                        </div>

                        <div style={{background:'',width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',marginRight:20}}>
                            <div style={{backgroundColor:'#D02630',border:'1px solid #B8222A',marginRight:25,display:'flex',flexDirection:'row',padding:'8px 25px 8px',alignItems:'center',justifyContent:'center',borderRadius:5}}>
                                <div style={{marginRight:7}}>
                                    <Icons icon="portable" size="40" color="#FFFFFF"/>
                                </div>
                                <div className='powerforall_H2' style={{}}>Donate</div>
                            </div>
                            
                        </div>


                    </div>

            </div>
        </div>
    )
}


export function SecondViewUI(){
    return(
        <div style={{padding:'60px 20px 60px'}}>

            <div style={{fontSize:'17px',fontFamily:"'Roboto', sans-serif",fontWeight:400,color:'#000000',textAlign:'left',lineHeight:'22px',opacity:0.7}}>
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria 
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria 
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria
            </div>

            <div style={{fontSize:'17px',fontFamily:"'Roboto', sans-serif",fontWeight:400,color:'#000000',textAlign:'left',lineHeight:'22px',opacity:0.7,paddingTop:20}}>
                Help power 50 primary health  power 50 primary health centers accross Nigeria 
                Help power 50 primary health centers accross Nigeria Help power 
                Help power 50  Nigeria Help power 50 primary health centers accross Nigeria
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria
            </div>
            
        </div>
    )
}





export function ThirdViewUI(){
    return(
        <div style={{padding:'',backgroundColor:'#F3F3F3'}}>

        <div style={{}}>
            <img src={require('../../images/new_images/powerforallclinic.jpg')} style={{width:'100%'}}/> 
        </div>
        <div style={{padding:'30px 20px 60px',}}>

            <div style={{fontSize:'17px',fontFamily:"'Roboto', sans-serif",fontWeight:400,color:'#000000',textAlign:'left',lineHeight:'22px',opacity:0.7}}>
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria 
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria 
            </div>

            <div style={{fontSize:'17px',fontFamily:"'Roboto', sans-serif",fontWeight:400,color:'#000000',textAlign:'left',lineHeight:'22px',opacity:0.7,paddingTop:20}}>
                Help power 50 primary health  power 50 primary health centers accross Nigeria 
                Help power 50 primary health centers accross Nigeria Help power 
                Help power 50  Nigeria Help power 50 primary health centers accross Nigeria
                Help power 50 primary health centers accross Nigeria Help power 50 primary health centers accross Nigeria
            </div>



            <div style={{background:'',width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',margin:'30px 0px 30px'}}>
                <div style={{backgroundColor:'#D02630',border:'1px solid #B8222A',display:'flex',flexDirection:'row',padding:'8px 25px 8px',alignItems:'center',justifyContent:'center',borderRadius:5}}>
                    <div style={{marginRight:7}}>
                        <Icons icon="portable" size="40" color="#FFFFFF"/>
                    </div>
                    <div className='powerforall_H3' style={{}}>Make Donation Now</div>
                </div>         
            </div>
            
        </div>
        </div>
    )
}



export function FooterMobile(){
    return(
        <div className="footer_wrapper">

            <div style={{}} className='footer_logo_holder_powerforall'>
                <div style={{}} className='footer_logo_carrier_powerforall'>
                    <Icons icon="logo" size="190" color="#33883F"/>
                </div>
            </div>


            <div style={{padding:'15px 20px 10px'}}>
                <div style={{}} className='footer_h1'>Our Products</div>
                <div style={{}} className='footer_h2'>Powerbox Pro</div>
                <div style={{}} className='footer_h2'>Powerbox SE</div>
            </div>


            {/* <div style={{padding:'0px 20px 10px'}}>
                <div style={{}} className='footer_h1'>Help</div>
                <div style={{}} className='footer_h2'>FAQs</div>
                <div style={{}} className='footer_h2'>Contact Us</div>
            </div> */}

            <div style={{padding:'0px 20px 10px'}}>
                <div style={{}} className='footer_h1'>Twitter</div>
                <div style={{}} className='footer_h2'>@powerboxEnergy</div>
            </div>

            <div style={{padding:'0px 20px 10px'}}>
                <div style={{}} className='footer_h1'>Email</div>
                <div style={{}} className='footer_h2'>Support@powerbox.com.ng</div>
            </div>

            <div style={{padding:'0px 20px 20px'}}>
                <div style={{}} className='footer_h1'>Legal</div>
                <div style={{}} className='footer_h2'>Privacy Policy</div>
            </div>


            <div className='last_footer_wrapper'>
                The Powerbox logo and technologies are trademark properties of Bitprime LLC and its regional subsidiaries. 
                <div style={{padding:'0px 20px 0px'}}>
                    &#169; 2021 Bitprime LLC. All Rights Reserved.
                </div> 
            </div>
            
        </div>  
    )  
}







export default function Home(){

    useEffect(() => {
        ReactGA.pageview(window.location);
    },[]);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })


    return(
    <div>

        <MainHeader/>

        <SecondViewUI/>

        <ThirdViewUI/>

        <FooterMobile/>

    </div>)
}