import React from 'react';

export default function Icons(props){

    switch(props.icon){
        case 'design':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 60 60">
                        <g style={{fill: props.color}}>
                            <path d="m52.192 40.325c-.9912762-1.4670275-2.6515271-2.3399545-4.422-2.325h-3.344l11.411-17.453c.2018671-.2990627.2269735-.6836811.065697-1.0064483-.1612764-.3227673-.483923-.5336247-.8442838-.5517602-.3603608-.0181354-.7025466.1592638-.8954132.4642085l-16.704 25.547h-14.918l-16.704-25.547c-.19286656-.3049447-.53505243-.4823439-.89541323-.4642085-.36036079.0181355-.68300733.2289929-.84428378.5517602-.16127644.3227672-.13617006.7073856.06569701 1.0064483l11.411 17.453h-3.344c-1.771936-.0147333-3.43324546.8598575-4.424 2.329l-7.306 11.05c-.32780034.4768332-.50223965 1.0423654-.5 1.621v4c0 1.6568542 1.34314575 3 3 3h54c1.6568542 0 3-1.3431458 3-3v-4c.0011577-.5753693-.1732759-1.1373941-.5-1.611zm-36.538-.325h1.228l4.281 6.547c.1846545.2825966.4994231.4529552.837.453h16c.3375769-.0000448.6523455-.1704034.837-.453l4.281-6.547h2.227l.95 2.175-.306.153c-1.3298694.6757789-1.9671103 2.2213004-1.5 3.638l1.069 3.206c.3472689 1.058644 1.2553834 1.8356507 2.355 2.015l2.505.42 1.051 2.393h-41.94zm-13.49 12.491 7.308-11.056c.620838-.9102138 1.6563042-1.4489716 2.758-1.435h1.241l-6.125 14h-4.226c-.58477269.0312839-1.08509482-.4154323-1.12-1 .00080568-.1825293.05809442-.360334.164-.509zm55.836 4.509c0 .5522847-.4477153 1-1 1h-54c-.55228475 0-1-.4477153-1-1v-1.208c.35788851.1357606.73723125.2062099 1.12.208h53.76c.3827687-.0017901.7621115-.0722394 1.12-.208zm-1.12-3h-3.226l-1.628-3.711c-.158974-.3642554-.5185648-.5997952-.916-.6l-2.868-.477c-.3645948-.061126-.6657233-.318412-.783-.669l-1.071-3.213c-.16067-.4733302.0528448-.992598.5-1.216l.87-.435c.3077613-.0459094.5766134-.232467.7273297-.5046972.1507164-.2722302.1661195-.5991062.0416703-.8843028l-1-2.29h.242c1.1002376-.0141962 2.1346466.5229008 2.756 1.431l7.318 11.069c.1022462.1466835.1570436.3211975.157.5-.0349052.5845677-.5352273 1.0312839-1.12 1z"/>
                            <path d="m29 18c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-2.4l3.474-1.867c.4865629-.261783.668783-.8684371.407-1.355s-.8684371-.668783-1.355-.407l-3.526 1.894-3.526-1.9c-.4865629-.261783-1.093217-.0795629-1.355.407s-.0795629 1.093217.407 1.355l3.474 1.873z"/>
                            <path d="m16.526 22.88 13 7c.2959236.1593002.6520764.1593002.948 0l13-7c.3237997-.1743063.525807-.5122652.526-.88v-14c-.0001367-.35174583-.1850653-.6775544-.487-.858-.013-.008-.028-.011-.042-.018l-13-7c-.2959236-.15930021-.6520764-.15930021-.948 0l-13 7c-.014.007-.029.01-.042.018-.2996323.18192763-.4821296.50746337-.481.858v14c.000193.3677348.2022003.7056937.526.88zm1.474-13.207 2.526 1.358c.4865629.261783 1.093217.0795629 1.355-.407s.0795629-1.09321703-.407-1.355l-2.363-1.269 10.889-5.864 10.889 5.864-2.363 1.27c-.4865629.26178297-.668783.8684371-.407 1.355s.8684371.668783 1.355.407l2.526-1.359v11.727l-11 5.926v-3.326c0-.5522847-.4477153-1-1-1s-1 .4477153-1 1v3.326l-11-5.926z"/>
                        </g>

                    </svg>
                    )
        break;



        case 'durable':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 64 64">
                        <g style={{fill: props.color}}>
                            <path d="M42,30A12,12,0,1,0,30,18,12.013,12.013,0,0,0,42,30ZM52,18a9.992,9.992,0,0,1-16.324,7.738l3.592-3.592a5.069,5.069,0,0,0,.505.307l-.3,1.127a.985.985,0,0,0-1,.721,1,1,0,0,0,.707,1.225l1.5.4a1,1,0,0,0,1.224-.707.986.986,0,0,0-.5-1.124l.3-1.128c.1.006.194.03.3.03a4.965,4.965,0,0,0,2.752-.833l.814.814a.99.99,0,0,0,1.54,1.226l1.1-1.1a1,1,0,0,0,0-1.414.987.987,0,0,0-1.226-.126l-.814-.814A4.966,4.966,0,0,0,47,18c0-.1-.024-.2-.03-.3l1.121-.3a.991.991,0,0,0,.871.537.974.974,0,0,0,.26-.035,1,1,0,0,0,.707-1.224l-.4-1.5a1,1,0,0,0-1.224-.707.984.984,0,0,0-.721,1l-1.129.3a5.045,5.045,0,0,0-.306-.5l3.592-3.592A9.949,9.949,0,0,1,52,18ZM39.3,19.285A2.964,2.964,0,0,1,39,18a3,3,0,0,1,3-3,2.964,2.964,0,0,1,1.285.3Zm5.4-2.57A2.965,2.965,0,0,1,45,18a3,3,0,0,1-3,3,2.965,2.965,0,0,1-1.285-.3ZM42,8a9.948,9.948,0,0,1,6.324,2.262l-3.592,3.592a4.887,4.887,0,0,0-.505-.307l.3-1.112c.013,0,.026,0,.039,0a1,1,0,0,0,.258-1.966l-1.5-.4a1,1,0,0,0-1.224.707.985.985,0,0,0,.5,1.124l-.3,1.128c-.1-.006-.194-.03-.3-.03a4.97,4.97,0,0,0-2.752.832l-.815-.814a.985.985,0,0,0-.126-1.225,1,1,0,0,0-1.414,0l-1.1,1.1a.987.987,0,0,0,1.225,1.541l.815.813A4.969,4.969,0,0,0,37,18c0,.1.024.2.03.3l-1.128.3a.983.983,0,0,0-1.124-.5,1,1,0,0,0-.707,1.224l.4,1.5a1,1,0,0,0,.965.741,1.015,1.015,0,0,0,.26-.034.986.986,0,0,0,.721-1l1.129-.3a5.045,5.045,0,0,0,.306.5l-3.592,3.592A9.992,9.992,0,0,1,42,8Z"/>
                            <path d="M61.5,60.132,56.173,57.09a19.9,19.9,0,0,0,3.472-2.326,1,1,0,1,0-1.291-1.528C45.881,63.793,13.535,56.981,13.23,56.915,5.332,55.051,4,52.624,4,47.889V10.7L21.506,4.248l4.323,8.646-5.247,7-9.376-1.98a1,1,0,0,0-.413,1.957l1.645.347a92.5,92.5,0,0,1,5.924,21.054c-.64.579-1.018.97-1.087,1.043a1,1,0,1,0,1.45,1.377c.083-.087,8.428-8.74,19.287-8.88h.265c6.049,0,11.773,2.656,17.016,7.9a1,1,0,0,0,1.414-1.414,36.812,36.812,0,0,0-2.929-2.632A9.87,9.87,0,0,1,61,36a1,1,0,0,0,0-2,11.669,11.669,0,0,0-8.845,3.431,23.5,23.5,0,0,0-14.169-4.622c-7.857.1-14.327,4.178-17.847,6.962A96.014,96.014,0,0,0,14.8,20.714l5.989,1.264A1,1,0,0,0,21.8,21.6l6-8a1,1,0,0,0,.094-1.046l-5-10a1,1,0,0,0-1.24-.492l-19,7A1,1,0,0,0,2,10V47.889c0,6.3,2.718,9.072,10.789,10.977a121.493,121.493,0,0,0,18.3,2.2q2.052.084,3.984.084c7.758,0,14.08-1.01,18.905-3.016L60.5,61.868A.988.988,0,0,0,61,62a1,1,0,0,0,.5-1.868Z"/>
                            <path d="M48.673,49.055c-12.278,4.251-19.023.171-19.135.1a1,1,0,0,0-1.079,1.684c.2.13,3.475,2.168,9.556,2.168a34.581,34.581,0,0,0,11.312-2.064,1,1,0,0,0-.654-1.89Z"/>
                        </g>

                    </svg>
                    )
        break;


        case 'speedometer':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 64 64">
                        <g style={{fill: props.color}}>
                            <path d="m32 2a30 30 0 1 0 30 30 30.037 30.037 0 0 0 -30-30zm0 58a28 28 0 1 1 28-28 28.031 28.031 0 0 1 -28 28z"/>
                            <path d="m39 47h-14a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8a1 1 0 0 0 -1-1zm-1 8h-12v-6h12z"/>
                            <path d="m32 7a25.022 25.022 0 0 0 -25 25 1 1 0 0 0 1 1h8a1 1 0 0 0 1-1 14.994 14.994 0 0 1 24.49-11.61l-6.49 5.3a7.04 7.04 0 1 0 3.31 3.31l5.3-6.49a14.923 14.923 0 0 1 3.39 9.49 1 1 0 0 0 1 1h8a1 1 0 0 0 1-1 25.022 25.022 0 0 0 -25-25zm-16.97 24h-6.01a22.939 22.939 0 0 1 2.58-9.62l5.22 3.01a16.742 16.742 0 0 0 -1.79 6.61zm2.79-8.34-5.21-3.01a23.066 23.066 0 0 1 7.04-7.04l3 5.2a17.162 17.162 0 0 0 -4.83 4.85zm13.18-7.61a16.883 16.883 0 0 0 -6.61 1.76l-3.01-5.21a22.939 22.939 0 0 1 9.62-2.58zm1 21.95a5 5 0 0 1 0-10 5.416 5.416 0 0 1 1.21.15l-3.26 2.66a.462.462 0 0 0 -.07.07 3 3 0 1 0 4.24 4.24.462.462 0 0 0 .07-.07l2.66-3.26a5.416 5.416 0 0 1 .15 1.21 5 5 0 0 1 -5 5zm.68-4.26a1.041 1.041 0 0 1 -1.39-.03.99.99 0 0 1 -.29-.71 1.014 1.014 0 0 1 .26-.68l7.7-6.28zm.32-17.69v-6.03a22.939 22.939 0 0 1 9.62 2.58l-3.01 5.21a16.883 16.883 0 0 0 -6.61-1.76zm13.18 7.61a16.765 16.765 0 0 0 -1.29-1.72l1.88-2.31a.994.994 0 0 0 -1.4-1.4l-2.32 1.89a17.876 17.876 0 0 0 -1.7-1.31l3-5.2a23.066 23.066 0 0 1 7.04 7.04zm2.79 8.34a16.742 16.742 0 0 0 -1.79-6.61l5.22-3.01a22.939 22.939 0 0 1 2.58 9.62z"/>
                            <path d="m44 39a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z"/>
                            <path d="m52 39a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z"/>
                            <path d="m20 39a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z"/>
                            <path d="m12 39a3 3 0 1 0 3 3 3 3 0 0 0 -3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z"/>
                            <path d="m25 41h2v2h-2z"/>
                            <path d="m29 41h5v2h-5z"/>
                            <path d="m36 41h2v2h-2z"/>
                        </g>
                    </svg>
                    )
        break;



        case 'solarpanel':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 128 128">
                        <g style={{fill: props.color}}>
                            <path d="M104.37,78.18V56.212L123,25.091a1.75,1.75,0,0,0-1.5-2.649H61.308a1.751,1.751,0,0,0-1.5.852l-34.423,57.5a1.749,1.749,0,0,0,1.5,2.648H87.077a1.749,1.749,0,0,0,1.5-.851L90.2,79.875c.055.013.11.021.166.029V101.62H64a1.75,1.75,0,0,0-1.75,1.75v10.5H54.54V112.5a1.75,1.75,0,0,0-1.75-1.75H48.538V97.5a1.75,1.75,0,0,0-1.75-1.75H42.57V91.5a1.75,1.75,0,0,0-1.75-1.75h-6a1.75,1.75,0,0,0-1.75,1.75v4.25H20.7V91.5a1.749,1.749,0,0,0-1.75-1.75h-6A1.75,1.75,0,0,0,11.2,91.5v4.25H6.98A1.75,1.75,0,0,0,5.23,97.5v24a1.75,1.75,0,0,0,1.75,1.75H46.788a1.75,1.75,0,0,0,1.75-1.75v-1.25H52.79a1.75,1.75,0,0,0,1.75-1.75v-1.13H64a1.75,1.75,0,0,0,1.75-1.75v-10.5H90.37v2.63H84.146a1.749,1.749,0,0,0-1.75,1.75v4.25H76.712a1.75,1.75,0,0,0-1.75,1.75v6a1.75,1.75,0,0,0,1.75,1.75H116.52a1.75,1.75,0,0,0,1.75-1.75v-6a1.75,1.75,0,0,0-1.75-1.75h-5.685V109.5a1.749,1.749,0,0,0-1.75-1.75H102.87V79.9A1.744,1.744,0,0,0,104.37,78.18ZM36.57,93.25h2.5v2.5h-2.5Zm-21.87,0h2.5v2.5H14.7Zm30.338,26.5H8.73V99.25H45.038Zm6-3h-2.5v-2.5h2.5Zm49.83-40.32h-8.5v-.173l8.5-14.2ZM49.876,79.942l4.789-8H70.49l-4.789,8Zm6.884-11.5,4.79-8H77.375l-4.79,8Zm-15.114-8H57.471l-4.789,8H36.856Zm6.884-11.5H64.356l-4.79,8H43.741Zm6.885-11.5H71.24l-4.789,8H50.626Zm42.614-11.5-4.79,8H77.414l4.79-8Zm-6.885,11.5-4.789,8H70.53l4.789-8Zm15.6,8H90.433l4.79-8h16.305Zm-6.885,11.5h-16.3l4.789-8h16.305Zm-20.384,0H63.645l4.789-8H84.259Zm1.983,3.5H97.759l-4.79,8h-16.3Zm32.17-26.5H97.318l4.789-8h16.306Zm-51.323-8H78.125l-4.789,8H57.51Zm-27.539,46H50.586l-4.789,8H29.972Zm51.323,8H69.779l4.79-8h16.3ZM114.77,119.75H78.462v-2.5H114.77Zm-7.435-6H85.9v-2.5h21.439Zm-13.465-6V79.93h5.5v27.82Z"/>
                            <path d="M6.5,24.75H9.374a13.651,13.651,0,0,0,2.757,6.639L10.09,33.43a1.754,1.754,0,0,0,2.48,2.48l2.041-2.041a13.651,13.651,0,0,0,6.639,2.757V39.5a1.75,1.75,0,0,0,3.5,0V36.626a13.651,13.651,0,0,0,6.639-2.757L33.43,35.91a1.754,1.754,0,1,0,2.48-2.48l-2.041-2.041a13.651,13.651,0,0,0,2.757-6.639H39.5a1.75,1.75,0,0,0,0-3.5H36.626a13.651,13.651,0,0,0-2.757-6.639L35.91,12.57a1.754,1.754,0,1,0-2.48-2.48l-2.041,2.04A13.66,13.66,0,0,0,24.75,9.374V6.5a1.75,1.75,0,0,0-3.5,0V9.374a13.66,13.66,0,0,0-6.639,2.756L12.57,10.09a1.754,1.754,0,0,0-2.48,2.48l2.041,2.041A13.651,13.651,0,0,0,9.374,21.25H6.5a1.75,1.75,0,0,0,0,3.5Zm16.5-12A10.25,10.25,0,1,1,12.75,23,10.261,10.261,0,0,1,23,12.75Z"/>
                            <path d="M12.98,106a1.75,1.75,0,0,0-1.75,1.75V114a1.75,1.75,0,0,0,3.5,0v-6.25A1.749,1.749,0,0,0,12.98,106Z"/>
                            <path d="M29.008,107.75H27.22l1.318-3.8a1.75,1.75,0,0,0-3.307-1.146l-2.123,6.125a1.749,1.749,0,0,0,1.653,2.323h1.788l-1.318,3.8a1.749,1.749,0,0,0,1.08,2.226,1.716,1.716,0,0,0,.573.1,1.751,1.751,0,0,0,1.654-1.177l2.123-6.125a1.749,1.749,0,0,0-1.653-2.323Z"/>
                        </g>
                    </svg>
                    )
        break;


        case 'electric':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 24 24">
                        <g style={{fill: props.color}}>
                            <path d="m5.99 18.51c-.128 0-.256-.049-.354-.146-1.699-1.7-2.636-3.96-2.636-6.364s.937-4.664 2.637-6.363c.195-.195.512-.195.707 0s.195.512 0 .707c-1.511 1.511-2.344 3.519-2.344 5.656s.833 4.145 2.344 5.656c.195.195.195.512 0 .707-.098.098-.226.147-.354.147z"/>
                            <path d="m3.87 20.63c-.128 0-.256-.049-.354-.146-2.267-2.268-3.516-5.281-3.516-8.484s1.249-6.216 3.517-8.483c.195-.195.512-.195.707 0s.195.512 0 .707c-2.079 2.078-3.224 4.84-3.224 7.776s1.145 5.698 3.224 7.776c.195.195.195.512 0 .707-.098.098-.226.147-.354.147z"/>
                            <path d="m20.13 20.63c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707 2.079-2.079 3.224-4.841 3.224-7.777s-1.145-5.698-3.224-7.776c-.195-.195-.195-.512 0-.707s.512-.195.707 0c2.268 2.267 3.517 5.28 3.517 8.483s-1.249 6.216-3.517 8.483c-.097.098-.225.147-.353.147z"/>
                            <path d="m18.01 18.51c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707 1.511-1.512 2.344-3.52 2.344-5.657s-.833-4.145-2.344-5.656c-.195-.195-.195-.512 0-.707s.512-.195.707 0c1.7 1.699 2.637 3.959 2.637 6.363s-.937 4.664-2.637 6.363c-.097.098-.225.147-.353.147z"/>
                            <path d="m9.573 20c-.076 0-.152-.017-.223-.052-.214-.107-.322-.35-.259-.581l1.753-6.367h-3.344c-.189 0-.362-.107-.447-.276-.084-.169-.066-.372.047-.523l5.983-8c.138-.185.385-.251.596-.161.212.09.335.313.298.541l-.888 5.419h3.411c.19 0 .364.108.449.279.084.171.063.375-.053.526l-6.927 9c-.097.126-.245.195-.396.195zm-1.074-8h3.001c.156 0 .303.073.398.197.094.125.125.286.084.436l-1.25 4.542 4.752-6.175h-2.984c-.147 0-.286-.064-.381-.176s-.136-.26-.113-.405l.653-3.982z"/>
                        </g>
                    </svg>
                    )
        break;



        case 'portable':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 64 64">
                        <g style={{fill: props.color}}>
                            <path d="m21 62h-9c-.553 0-1-.447-1-1v-4.655l-8.787-11.229c-.138-.176-.213-.392-.213-.616v-22c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v12.086l12.707 12.707c.188.187.293.441.293.707v13c0 .553-.447 1-1 1zm-8-2h7v-11.586l-8.883-8.882c-.34-.339-.809-.532-1.289-.532-1.008 0-1.828.82-1.828 1.828 0 .481.195.953.535 1.293l6.172 6.172-1.414 1.414-6.172-6.172c-.723-.723-1.121-1.684-1.121-2.707 0-1.733 1.159-3.202 2.742-3.671l-1.449-1.45c-.188-.187-.293-.441-.293-.707v-12.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v21.655l8.787 11.229c.138.176.213.392.213.616z"/>
                            <path d="m52 62h-9c-.553 0-1-.447-1-1v-13c0-.266.105-.52.293-.707l12.707-12.707v-12.086c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v22c0 .224-.075.44-.213.616l-8.787 11.229v4.655c0 .553-.447 1-1 1zm-8-2h7v-4c0-.224.075-.44.213-.616l8.787-11.229v-21.655c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v12.5c0 .266-.105.52-.293.707l-1.449 1.45c1.583.469 2.742 1.938 2.742 3.671 0 1.022-.398 1.984-1.121 2.707l-6.172 6.172-1.414-1.414 6.172-6.172c.34-.34.535-.811.535-1.293 0-1.008-.82-1.828-1.828-1.828-.479 0-.949.193-1.289.532l-8.883 8.882z"/>
                            <path d="m43 24c-.158 0-.316-.037-.462-.113-.331-.172-.538-.514-.538-.887v-3h-10v-2h11c.553 0 1 .447 1 1v2.079l7.256-5.079-7.256-5.079v2.079c0 .553-.447 1-1 1h-21v3c0 .373-.207.715-.538.887-.331.173-.729.147-1.035-.067l-10-7c-.268-.188-.427-.494-.427-.82s.159-.632.427-.819l10-7c.306-.215.704-.24 1.035-.067.331.171.538.513.538.886v3h10v2h-11c-.553 0-1-.447-1-1v-2.079l-7.256 5.079 7.256 5.079v-2.079c0-.553.447-1 1-1h21v-3c0-.373.207-.715.538-.887.331-.174.729-.147 1.035.067l10 7c.268.188.427.494.427.82s-.159.632-.427.819l-10 7c-.171.12-.372.181-.573.181z"/>
                            <path d="m34 6h2v2h-2z"/>
                            <path d="m38 6h2v2h-2z"/>
                            <path d="m24 18h2v2h-2z"/>
                            <path d="m28 18h2v2h-2z"/>
                            <path d="m43 42c-.158 0-.316-.037-.462-.113-.331-.172-.538-.514-.538-.887v-3h-10v-2h11c.553 0 1 .447 1 1v2.079l7.256-5.079-7.256-5.079v2.079c0 .553-.447 1-1 1h-21v3c0 .373-.207.715-.538.887-.331.173-.729.147-1.035-.067l-10-7c-.268-.188-.427-.494-.427-.82s.159-.632.427-.819l10-7c.306-.215.704-.24 1.035-.067.331.171.538.513.538.886v3h10v2h-11c-.553 0-1-.447-1-1v-2.079l-7.256 5.079 7.256 5.079v-2.079c0-.553.447-1 1-1h21v-3c0-.373.207-.715.538-.887.331-.173.729-.147 1.035.067l10 7c.268.188.427.494.427.82s-.159.632-.427.819l-10 7c-.171.12-.372.181-.573.181z"/>
                            <path d="m34 24h2v2h-2z"/>
                            <path d="m38 24h2v2h-2z"/>
                            <path d="m24 36h2v2h-2z"/>
                            <path d="m28 36h2v2h-2z"/>
                        </g>
                    </svg>
                    )
        break;



        case 'ok':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 512 512">
                        <g style={{fill: props.color}}>
                            <g>
                                <path d="M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.331,26.629,74.98,74.98C26.628,123.332,0,187.62,0,256
                                    s26.628,132.667,74.98,181.019C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.981
                                    C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M256,482C131.383,482,30,380.617,30,256S131.383,30,256,30
                                    s226,101.383,226,226S380.617,482,256,482z"/>
                            </g>

                            <g>
                                <path d="M378.305,173.859c-5.857-5.856-15.355-5.856-21.212,0.001L224.634,306.319l-69.727-69.727
                                    c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l80.333,80.333c2.929,2.929,6.768,4.393,10.606,4.393
                                    c3.838,0,7.678-1.465,10.606-4.393l143.066-143.066C384.163,189.215,384.163,179.717,378.305,173.859z"/>
                            </g>

                        </g>
                    </svg>
                    )
        break;



        case 'naira':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 496.262 496.262">
                        <g style={{fill: props.color}}>
                            <path d="M477.832,274.28h-67.743v-65.106h67.743c10.179,0,18.43-8.243,18.43-18.424c0-10.182-8.251-18.43-18.43-18.43h-67.743
                                V81.982c0-13.187-2.606-22.866-7.743-28.762c-4.882-5.609-11.301-8.219-20.19-8.219c-8.482,0-14.659,2.592-19.447,8.166
                                c-5.077,5.902-7.654,15.599-7.654,28.821v90.343H227.627l-54.181-81.988c-4.637-7.317-8.997-14.171-13.231-20.75
                                c-3.812-5.925-7.53-10.749-11.042-14.351c-3.109-3.189-6.652-5.657-10.796-7.554c-3.91-1.785-8.881-2.681-14.762-2.681
                                c-7.501,0-14.31,2.055-20.83,6.277c-6.452,4.176-10.912,9.339-13.636,15.785c-2.391,6.126-3.656,15.513-3.656,27.63v77.626h-67.07
                                C8.246,172.326,0,180.574,0,190.755c0,10.181,8.246,18.424,18.424,18.424h67.07v65.113h-67.07C8.246,274.292,0,282.538,0,292.722
                                C0,302.9,8.246,311.14,18.424,311.14h67.07v103.143c0,12.797,2.689,22.378,8.015,28.466c5.065,5.805,11.487,8.5,20.208,8.5
                                c8.414,0,14.786-2.707,20.07-8.523c5.411-5.958,8.148-15.533,8.148-28.442V311.14h115.308l62.399,95.683
                                c4.339,6.325,8.819,12.709,13.287,18.969c4.031,5.621,8.429,10.574,13.069,14.711c4.179,3.742,8.659,6.484,13.316,8.157
                                c4.794,1.726,10.397,2.601,16.615,2.601c16.875,0,34.158-5.166,34.158-43.479V311.14h67.743c10.179,0,18.43-8.252,18.43-18.43
                                C496.262,282.532,488.011,274.28,477.832,274.28z M355.054,209.173v65.106h-60.041l-43.021-65.106H355.054z M141.936,134.364
                                l24.76,37.956h-24.76V134.364z M141.936,274.28v-65.106h48.802l42.466,65.106H141.936z M355.054,365.153l-35.683-54.013h35.683
                                V365.153z"/>
                        </g>
                    </svg>
                    )
        break;



        case 'logo':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 492 83">
                        <g style={{fill: props.color}}>
                        <path d="M421.54,22.41a26.93,26.93,0,0,0-38,0,25.93,25.93,0,0,0-7.9,19,25.9,25.9,0,0,0,7.9,19,26.93,26.93,0,0,0,38,0,26,26,0,0,0,7.84-19A26,26,0,0,0,421.54,22.41ZM409.72,48.65a10.18,10.18,0,0,1-17.39-7.21,9.81,9.81,0,0,1,3-7.21,10.26,10.26,0,0,1,14.42,0,10.14,10.14,0,0,1,0,14.42Z" />
                        <path d="M488.2,23A25.9,25.9,0,0,1,481,41.26q7.17,7.26,7.16,18.62V60a8.35,8.35,0,1,1-16.7,0v-.09q0-6.84-5.7-9.13a11.86,11.86,0,0,0-4.47-.82h-.05a12.34,12.34,0,0,0-4.2.69l-.27.09q-5.7,2.32-5.7,9.17V60a8.1,8.1,0,0,1-2.47,5.93A8.35,8.35,0,0,1,434.41,60v-.09q0-11.37,7.11-18.62A26,26,0,0,1,434.41,23v-.09A8,8,0,0,1,436.87,17a8.34,8.34,0,0,1,11.77,0,8.08,8.08,0,0,1,2.47,5.93V23a9.8,9.8,0,0,0,3,7.21,9.93,9.93,0,0,0,7.17,3h.09a9.9,9.9,0,0,0,7.16-3,9.73,9.73,0,0,0,3-7.21v-.09a8.35,8.35,0,1,1,16.7,0Z" />
                        <path d="M107.31,22.41a26.93,26.93,0,0,0-38,0,26,26,0,0,0-7.9,19,25.94,25.94,0,0,0,7.9,19,26.93,26.93,0,0,0,38,0,26,26,0,0,0,7.85-19A26,26,0,0,0,107.31,22.41ZM95.49,48.65a10.17,10.17,0,0,1-17.38-7.21,9.8,9.8,0,0,1,3-7.21,10.26,10.26,0,0,1,14.42,0,10.14,10.14,0,0,1,0,14.42Z" />
                        <path d="M49.91,22.41a26,26,0,0,0-19-7.85,25.86,25.86,0,0,0-19,7.85A26,26,0,0,0,4,41.44V71.11a8.33,8.33,0,0,0,8.35,8.35,8.33,8.33,0,0,0,8.35-8.35v-4.8a26.5,26.5,0,0,0,10.17,2,26,26,0,0,0,19-7.85,26,26,0,0,0,7.84-19A26,26,0,0,0,49.91,22.41ZM38.09,48.65a10.12,10.12,0,0,1-14.42,0,10.24,10.24,0,0,1,0-14.42,10.26,10.26,0,0,1,14.42,0,10.14,10.14,0,0,1,0,14.42Z" />
                        <path d="M312.06,41.44a8.31,8.31,0,0,1-8.3,8.35,8.09,8.09,0,0,1-5.93-2.46,8,8,0,0,1-2.42-5.89,10.19,10.19,0,0,0-10.22-10.17,9.92,9.92,0,0,0-7.21,3,9.81,9.81,0,0,0-3,7.21V60a8.09,8.09,0,0,1-2.46,5.93,8.38,8.38,0,0,1-11.78,0A8.09,8.09,0,0,1,258.31,60V41.44a26,26,0,0,1,7.89-19,26.94,26.94,0,0,1,38,0A26,26,0,0,1,312.06,41.44Z" />
                        <path d="M370.79,45.14a18.74,18.74,0,0,0-5.29-9.63,18.85,18.85,0,0,0,4.7-9.67,18.93,18.93,0,0,0-1.05-10.5,17.43,17.43,0,0,0-6.89-8.44,23.06,23.06,0,0,0-12.91-3.47H329.54a13.29,13.29,0,0,0-9.81,4.11,13.29,13.29,0,0,0-4.11,9.81v37.1a13.86,13.86,0,0,0,13.92,13.87h20.53a23,23,0,0,0,13.15-3.6A17.72,17.72,0,0,0,370.11,56,19.59,19.59,0,0,0,370.79,45.14Zm-38.47,6.53V42h17.8a4.13,4.13,0,0,1,3.24,1.51,5.43,5.43,0,0,1,.37,6.66,4.48,4.48,0,0,1-3.66,1.51Zm0-31.54h17A4.47,4.47,0,0,1,353,21.64a5.45,5.45,0,0,1,.95,3.29,5.56,5.56,0,0,1-1.32,3.33,4.15,4.15,0,0,1-3.24,1.5H332.32Z" />
                        <path d="M195.06,22.92V45.46a22,22,0,0,1-6.71,16.15,22,22,0,0,1-16.2,6.71,22.27,22.27,0,0,1-14.55-5.2,22.9,22.9,0,0,1-37.42-17.66V22.92a8.35,8.35,0,1,1,16.7,0V45.46a6,6,0,0,0,1.83,4.38,5.85,5.85,0,0,0,4.38,1.83,6.23,6.23,0,0,0,6.2-6.21V22.92a8.33,8.33,0,1,1,16.66,0V45.46a6.23,6.23,0,0,0,6.2,6.21,5.85,5.85,0,0,0,4.38-1.83,6,6,0,0,0,1.83-4.38V22.92a8.35,8.35,0,0,1,8.35-8.36A8,8,0,0,1,192.6,17,8.07,8.07,0,0,1,195.06,22.92Z" />
                        <path d="M252.15,32.36a26.62,26.62,0,0,0-9.81-12.91,26.86,26.86,0,0,0-34.36,3,25.85,25.85,0,0,0-7.9,19,25,25,0,0,0,4,13.83A26.94,26.94,0,0,0,215.23,65a31.46,31.46,0,0,0,14.1,3.28,29.67,29.67,0,0,0,16.38-4.7,8.06,8.06,0,0,0,3.61-5.25,8.15,8.15,0,0,0-1.1-6.29A8.06,8.06,0,0,0,243,48.47a8.16,8.16,0,0,0-6.29,1.1,13.23,13.23,0,0,1-7.35,2,15.43,15.43,0,0,1-6.8-1.5,5.16,5.16,0,0,1-.68-.42l24.23-6.39a8.55,8.55,0,0,0,1-.32,8.34,8.34,0,0,0,5-10.63Zm-32.4,1.92a10.14,10.14,0,0,1,13-1.28,10.05,10.05,0,0,1,2,1.83L217,39.53A9.89,9.89,0,0,1,219.75,34.28Z" />
                        </g>
                    </svg>
                    )
        break;



        case 'logo2':
            return(
                    <svg width={ props.size }  height={ props.size }  viewBox="0 0 492 83">
                        <g style={{fill: props.color}}>
                            <path d="M421.54,22.41a26.93,26.93,0,0,0-38,0,25.93,25.93,0,0,0-7.9,19,25.9,25.9,0,0,0,7.9,19,26.93,26.93,0,0,0,38,0,26,26,0,0,0,7.84-19A26,26,0,0,0,421.54,22.41ZM409.72,48.65a10.18,10.18,0,0,1-17.39-7.21,9.81,9.81,0,0,1,3-7.21,10.26,10.26,0,0,1,14.42,0,10.14,10.14,0,0,1,0,14.42Z"/>
                            <path d="M488.2,23A25.9,25.9,0,0,1,481,41.26q7.17,7.26,7.16,18.62V60a8.35,8.35,0,1,1-16.7,0v-.09q0-6.84-5.7-9.13a11.86,11.86,0,0,0-4.47-.82h-.05a12.34,12.34,0,0,0-4.2.69l-.27.09q-5.7,2.32-5.7,9.17V60a8.1,8.1,0,0,1-2.47,5.93A8.35,8.35,0,0,1,434.41,60v-.09q0-11.37,7.11-18.62A26,26,0,0,1,434.41,23v-.09A8,8,0,0,1,436.87,17a8.34,8.34,0,0,1,11.77,0,8.08,8.08,0,0,1,2.47,5.93V23a9.8,9.8,0,0,0,3,7.21,9.93,9.93,0,0,0,7.17,3h.09a9.9,9.9,0,0,0,7.16-3,9.73,9.73,0,0,0,3-7.21v-.09a8.35,8.35,0,1,1,16.7,0Z"/>
                            <path d="M107.31,22.41a26.93,26.93,0,0,0-38,0,26,26,0,0,0-7.9,19,25.94,25.94,0,0,0,7.9,19,26.93,26.93,0,0,0,38,0,26,26,0,0,0,7.85-19A26,26,0,0,0,107.31,22.41ZM95.49,48.65a10.17,10.17,0,0,1-17.38-7.21,9.8,9.8,0,0,1,3-7.21,10.26,10.26,0,0,1,14.42,0,10.14,10.14,0,0,1,0,14.42Z"/>
                            <path d="M49.91,22.41a26,26,0,0,0-19-7.85,25.86,25.86,0,0,0-19,7.85A26,26,0,0,0,4,41.44V71.11a8.33,8.33,0,0,0,8.35,8.35,8.33,8.33,0,0,0,8.35-8.35v-4.8a26.5,26.5,0,0,0,10.17,2,26,26,0,0,0,19-7.85,26,26,0,0,0,7.84-19A26,26,0,0,0,49.91,22.41ZM38.09,48.65a10.12,10.12,0,0,1-14.42,0,10.24,10.24,0,0,1,0-14.42,10.26,10.26,0,0,1,14.42,0,10.14,10.14,0,0,1,0,14.42Z"/>
                            <path d="M312.06,41.44a8.31,8.31,0,0,1-8.3,8.35,8.09,8.09,0,0,1-5.93-2.46,8,8,0,0,1-2.42-5.89,10.19,10.19,0,0,0-10.22-10.17,9.92,9.92,0,0,0-7.21,3,9.81,9.81,0,0,0-3,7.21V60a8.09,8.09,0,0,1-2.46,5.93,8.38,8.38,0,0,1-11.78,0A8.09,8.09,0,0,1,258.31,60V41.44a26,26,0,0,1,7.89-19,26.94,26.94,0,0,1,38,0A26,26,0,0,1,312.06,41.44Z"/>
                            <path d="M370.79,45.14a18.74,18.74,0,0,0-5.29-9.63,18.85,18.85,0,0,0,4.7-9.67,18.93,18.93,0,0,0-1.05-10.5,17.43,17.43,0,0,0-6.89-8.44,23.06,23.06,0,0,0-12.91-3.47H329.54a13.29,13.29,0,0,0-9.81,4.11,13.29,13.29,0,0,0-4.11,9.81v37.1a13.86,13.86,0,0,0,13.92,13.87h20.53a23,23,0,0,0,13.15-3.6A17.72,17.72,0,0,0,370.11,56,19.59,19.59,0,0,0,370.79,45.14Zm-38.47,6.53V42h17.8a4.13,4.13,0,0,1,3.24,1.51,5.43,5.43,0,0,1,.37,6.66,4.48,4.48,0,0,1-3.66,1.51Zm0-31.54h17A4.47,4.47,0,0,1,353,21.64a5.45,5.45,0,0,1,.95,3.29,5.56,5.56,0,0,1-1.32,3.33,4.15,4.15,0,0,1-3.24,1.5H332.32Z"/>
                            <path d="M195.06,22.92V45.46a22,22,0,0,1-6.71,16.15,22,22,0,0,1-16.2,6.71,22.27,22.27,0,0,1-14.55-5.2,22.9,22.9,0,0,1-37.42-17.66V22.92a8.35,8.35,0,1,1,16.7,0V45.46a6,6,0,0,0,1.83,4.38,5.85,5.85,0,0,0,4.38,1.83,6.23,6.23,0,0,0,6.2-6.21V22.92a8.33,8.33,0,1,1,16.66,0V45.46a6.23,6.23,0,0,0,6.2,6.21,5.85,5.85,0,0,0,4.38-1.83,6,6,0,0,0,1.83-4.38V22.92a8.35,8.35,0,0,1,8.35-8.36A8,8,0,0,1,192.6,17,8.07,8.07,0,0,1,195.06,22.92Z"/>
                            <path d="M252.15,32.36a26.62,26.62,0,0,0-9.81-12.91,26.86,26.86,0,0,0-34.36,3,25.85,25.85,0,0,0-7.9,19,25,25,0,0,0,4,13.83A26.94,26.94,0,0,0,215.23,65a31.46,31.46,0,0,0,14.1,3.28,29.67,29.67,0,0,0,16.38-4.7,8.06,8.06,0,0,0,3.61-5.25,8.15,8.15,0,0,0-1.1-6.29A8.06,8.06,0,0,0,243,48.47a8.16,8.16,0,0,0-6.29,1.1,13.23,13.23,0,0,1-7.35,2,15.43,15.43,0,0,1-6.8-1.5,5.16,5.16,0,0,1-.68-.42l24.23-6.39a8.55,8.55,0,0,0,1-.32,8.34,8.34,0,0,0,5-10.63Zm-32.4,1.92a10.14,10.14,0,0,1,13-1.28,10.05,10.05,0,0,1,2,1.83L217,39.53A9.89,9.89,0,0,1,219.75,34.28Z"/>
                        </g>
                    </svg>
                    )
        break;


        default:
            return null
        break;
        }
    
}