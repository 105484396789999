import React ,{useEffect, useState} from 'react';
import { CSSTransitionGroup } from 'react-transition-group'

import { useMediaQuery } from 'react-responsive'
import '../../styles/index.css';
import boxMainImmage from '../../images/new_images/front-direct-image-1.png';
import boxSidePotraite from '../../images/side-potrait.png';
import boxFront from '../../images/front-strieght.png';
import boxFrontSided from '../../images/front-sided.png';
// import boxBack from '../../images/back-striaght.png';
// import boxBackSided from '../../images/back-sided.png';
import boxBackSided from '../../images/new_images/back-side.png';
import boxLoadSupport from '../../images/new_images/load_support.png';
import boxBackSidedNew from '../../images/new_images/back_sided-new.png';
import boxSided from '../../images/new_images/sided_new.png';

import lhgp_Logo from '../../images/new_images/lhgp_logo.jpeg';
import afritech_Logo from '../../images/new_images/afritech_logo.png';



import boxFrontFacingPowerBtn from '../../images/new_images/front_sided_power_btn_focus.png';
import Icons from '../icons';
import ReactGA from 'react-ga';

const screenWidth = window.screen.width;
const screenHeight = (window.screen.height - 60);






export function Header(){
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })

    return(
        <div style={{
            height:'50px',position:'fixed',left:'0px',right:'0px',display:'flex',flexDirection:'row',justifyContent:'space-between',
            alignItems:'center',zIndex:1000,overflow:'hidden'
            }}>

            <div style={{paddingLeft:'15px'}}>
                {(isDesktopOrLaptop)?
                <Icons icon="logo" size="180" color="#429a37"/>:
                <Icons icon="logo" size="140" color="#429a37"/>}
            </div>
            
            <div style={{}}></div>
        </div>
    )
} 


export function TopStage(){

    const [showTopBuy, setShowTopBuy] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })

      function openTopBuy(){
        setShowTopBuy(true);
      }

      const openWhatsapp = () => {
        window.open("https://wa.me/+2349070811425?text=Hi", "_blank");
        ReactGA.pageview("whatsapp clicked");
      }





      return(
        <div className="topHeaderStage"style={{height:(isDesktopOrLaptop)?screenHeight:undefined,display:'flex',justifyContent:'space-between',alignItems:'center',flexDirection:'column',overflow:'hidden'}}>
            
            <div style={{padding:(isDesktopOrLaptop)?'100px 10px 0px':'100px 6px 0px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', maxWidth:(screenWidth - 400)}}>
                
                {(isDesktopOrLaptop)?
                <div style={{textAlign:'center'}} className="H1_pc">
                    Smart Hybrid Irrigation Systems
                </div> 
                :
                <div style={{textAlign:'center'}} className="H1">
                    Smart Hybrid Irrigation Systems
                </div>
                
                }


                {(isDesktopOrLaptop)?
                <div style={{padding:'15px 20px 15px'}} className="H3_pc">
                    <div>
                        Award-winning advanced made-in-Nigeria smart irrigation systems with mobile app
                    </div> 
                    <div>
                        and automated climate control features. 
                    </div>
                </div> :

                <div style={{padding:'15px 0px 15px'}} className="H3_mobile">
                <div>
                    Award-winning advanced made-in-Nigeria smart irrigation systems with mobile app and automated climate control features.
                </div> 
                
                </div>
                }
                

                <div style={{}}>
                   <div className="contactus_btn_pc" style={{padding:'12px 30px 12px',marginBottom:35,backgroundColor:'#008BE2',fontSize:15,borderRadius:5,color:'#FFFFFF' }}>
                        Contact Us
                   </div>
                </div> 
            </div>

            
            <div style={{marginBottom:-5}}>
                
                <div style={{}}>
                    {(isDesktopOrLaptop)?
                    <img src={require('../../images/new_images/irregation_works_header_with_windvain.png')} style={{height:(screenHeight - 270)}}/>
                    :
                    <img src={require('../../images/new_images/irregation_works_header_with_windvain.png')} style={{width:(screenWidth - 10),paddingBottom:50}}/>
                    }
                </div>

            </div>

        </div>
      )

}






export function SecondStagePc(){



    return(
        <div style={{backgroundColor:'#F5F5F7',height:(screenHeight - 50),overflow:'hidden'}}>
            
            <div style={{padding:15,display:'flex',flexDirection:'row',}}>
                
                <div style={{backgroundColor:'pink',flex:1,marginRight:10,height:(screenHeight - 50) - 30,overflow:'hidden',borderRadius:2}}>
                    <img src={require('../../images/new_images/irrigation1.jpg')} style={{width:'100%',maxWidth:'100%',maxHeight:'100%',verticalAlign:'middle'}}/>
                </div>

                <div style={{backgroundColor:'',flex:1,marginLeft:10,display:'flex',justifyContent:'center',flexDirection:'column'}}>
                    <div style={{padding:'10px 10px 10px'}}>
                        <div className="desc_h_pc" style={{backgroundColor:'',flex:1,marginLeft:10,textAlign:'left'}}>
                        Smart Irrigation Solution
                        </div>
                        <div style={{padding:'10px 0px 10px 20px'}} className="normal_desc_pc" style={{backgroundColor:'',flex:1,marginLeft:10,textAlign:'left'}}>
                            Powerbox smart irrigation solutions are a promising way to address the challenges of Agriculture in Africa, where water scarcity and climate change are major concerns. 
                            <br/><br/>Our approach is to use sensors and and other technologies to monitor soil moisture levels and automatically adjust irrigation systems to provide plants with the right amount of water at the right time. This can help conserve water and improve crop yields. 
                            <br/><br/>Additionally, using advanced irrigation systems, such as drip and micro-spray systems, can help to reduce water waste and improve the efficiency of irrigation.Overall, smart irrigation solutions can help to support sustainable agriculture and increase food security in Africa.
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}






export function SecondStageMobile(){

    return(
        <div style={{backgroundColor:'#F5F5F7',overflow:'hidden'}}>
            
            <div style={{paddingTop:2,display:'flex',flexDirection:'column',}}>
                
                

                <div style={{backgroundColor:'',flex:1,marginLeft:0,display:'flex',justifyContent:'center',flexDirection:'column'}}>
                    <div style={{padding:'10px 5px 10px'}}>
                        <div className="desc_h_pc" style={{backgroundColor:'',flex:1,marginLeft:10,textAlign:'left'}}>
                        Smart Irrigation Solution
                        </div>
                        <div style={{padding:'10px 0px 10px 0px'}} className="normal_desc_pc" style={{backgroundColor:'',flex:1,marginLeft:10,textAlign:'left'}}>
                        Powerbox smart irrigation solutions are a promising way to address the challenges of Agriculture in Africa, where water scarcity and climate change are major concerns. 
                            <br/><br/>Our approach is to use sensors and and other technologies to monitor soil moisture levels and automatically adjust irrigation systems to provide plants with the right amount of water at the right time. This can help conserve water and improve crop yields. 
                            <br/><br/>Additionally, using advanced irrigation systems, such as drip and micro-spray systems, can help to reduce water waste and improve the efficiency of irrigation.Overall, smart irrigation solutions can help to support sustainable agriculture and increase food security in Africa.
                        </div>
                    </div>
                </div>


                <div style={{backgroundColor:'pink',flex:1,marginRight:10,width:(screenWidth),overflow:'hidden',borderRadius:2}}>
                    <img src={require('../../images/new_images/irrigation1.jpg')} style={{width:'100%',maxWidth:'100%',maxHeight:(screenWidth),verticalAlign:'middle'}}/>
                </div>

            </div>

        </div>
    )
}









export function ThirdStagePc(){





    return(
        <div style={{height:(screenHeight - 60),display:'flex',justifyContent:'space-between',flexDirection:'column',backgroundColor:'#ECEDEA'}}>
            
            <div className="desc_for_box" style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
            One Stop Irrigation Solution for Africa
            </div>
            
            <div style={{display:'flex',justifyContent:'center',paddingBottom:10}}>
                
                <div className="desc_for_box_sides" style={{ }}>
                    <div className="desc_for_box_sides_children" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                       
                        <div className='desc_for_box_sides_carrier' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                            <div className="sub2PcCircles design_icon" style={{borderRadius:'100px',}}>
                            <img src={require('../../images/new_images/designer_icon.png')} style={{width:40,height:40}}/>
                            </div>
                            
                            <div style={{flex:1}}>
                                <div style={{textAlign:'right',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Modular and integrated design.</div>
                            </div>
                        </div>

                    </div>

                    <div className="desc_for_box_sides_children" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    
                    
                    <div className='desc_for_box_sides_carrier'>
                        
                        <div className='desc_for_box_sides_carrier'>
                            
                            <div className="sub2PcCircles design_icon2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#FAA44',borderRadius:'100px'}}>
                                    <img src={require('../../images/new_images/arm.png')} style={{width:45,height:45}}/>
                                </div>
                            
                            <div style={{flex:1}}>
                                {/* <div style={{}} className="desc_for_box_sides_h2">Rigid and Durable</div> */}
                                <div style={{textAlign:'center',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Manufactured from high grade steel, designed to withstand extreme environments without damage.</div>
                            </div>
                        </div>


                        </div>
                    </div>


                    <div className="desc_for_box_sides_children" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                        
                        <div className='desc_for_box_sides_carrier' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                            <div className="sub2PcCircles design_icon3" style={{justifyContent:'center',alignItems:'center',display:'flex',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/speed.png')} style={{width:45,height:45}}/>
                            </div>
                            
                            <div style={{flex:1}}>
                                <div style={{textAlign:'right',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Excellent battery storage solution for days with low sunlight.</div>

                            </div> 
                        </div>


                    </div>
                </div>
                
                <div style={{paddingBottom:3}}>
                    <img src={require('../../images/new_images/front_sided_edited1.png')} style={{maxWidth:700}}/>
                </div>
                
                <div className="desc_for_box_sides" style={{ }}>
                    
                    <div className="desc_for_box_sides_children" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    
                        <div className='desc_for_box_sides_carrier2'>

                            <div className="sub2PcCircles2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#FF3F2C',borderRadius:'100px'}}>
                                    <img src={require('../../images/new_images/solar-energy.png')} style={{width:45,height:45}}/>
                            </div>

                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                                <div style={{textAlign:'left',paddingTop:10}} className="desc_for_box_sides_carrier_text">Comes with High Powered Solar Panels.</div>
                            </div>

                        </div>
                    </div>

                    <div className="desc_for_box_sides_children" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        
                    <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                            
                        
                        <div className='desc_for_box_sides_carrier2' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            
                            <div className="sub2PcCircles2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#0071E2',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/energetic.png')} style={{width:45,height:45}}/>
                            </div>

                                <div style={{textAlign:'center',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Operates 24-hours everyday.</div>
                            </div>

                        </div>


                    </div>


                    <div className="desc_for_box_sides_children" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                        
                        
                        <div className='desc_for_box_sides_carrier2' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                            
                            <div className="sub2PcCircles2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#E5AAFA',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/pinch.png')} style={{width:45,height:45}}/>
                            </div>

                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-end',}}>
                                <div style={{textAlign:'left',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Also supports third-party irrigation pumps.</div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
            
        </div>
    )



}










export function ThirdStageMobile(){
    return(
        <div style={{backgroundColor:'#FFFFFF'}}>
            
            <div style={{display:'flex',flexDirection:'row',padding:'15px 10px 15px'}}>
                
                <div style={{flex:1,padding:'0px 5px 10px 0px'}}>

                        <div className='desc_for_box_sides_carrier' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div className="sub2PcCircles_mobile design_icon" style={{borderRadius:'100px',}}>
                            <img src={require('../../images/new_images/designer_icon.png')} style={{width:35,height:35}}/>
                            </div>
                            
                            <div style={{flex:1}}>
                                <div style={{textAlign:'right',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Modular and integrated design.</div>
                            </div>
                        </div>


                </div>


                <div style={{flex:1,padding:'0px 0px 10px 5px'}}>

                        <div className='desc_for_box_sides_carrier'>
                            
                            <div className="sub2PcCircles design_icon2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#FAA44',borderRadius:'100px'}}>
                                    <img src={require('../../images/new_images/arm.png')} style={{width:35,height:35}}/>
                                </div>
                            
                            <div style={{flex:1}}>
                                <div style={{textAlign:'center',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Manufactured from high grade steel, the Powerbox will withstand extreme environments without damage.</div>
                            </div>
                        </div>
                </div>


            </div>
            
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div style={{maxWidth:300}}>
                    <img src={require('../../images/new_images/front_sided_edited1.png')} style={{width:'100%'}}/>
                </div>
            </div>
            

            <div style={{display:'flex',flexDirection:'row',padding:'35px 10px 15px'}}>

                <div style={{flex:1,padding:'0px 5px 10px 0px'}}>

                    <div className='desc_for_box_sides_carrier2' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

                        <div className="sub2PcCircles2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#FF3F2C',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/solar-energy.png')} style={{width:35,height:35}}/>
                        </div>

                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',}}>
                            <div style={{textAlign:'center',paddingTop:10}} className="desc_for_box_sides_carrier_text">Comes with High Powered Solar Panels.</div>
                        </div>

                    </div>

                </div>


                
                <div style={{flex:1,padding:'0px 0px 10px 5px'}}>

                <div className='desc_for_box_sides_carrier2' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            
                            <div className="sub2PcCircles2" style={{justifyContent:'center',alignItems:'center',display:'flex',backgroundColor:'#0071E2',borderRadius:'100px'}}>
                                <img src={require('../../images/new_images/energetic.png')} style={{width:35,height:35}}/>
                            </div>

                                <div style={{textAlign:'center',maxWidth:280,paddingTop:10}} className="desc_for_box_sides_carrier_text">Also supports third-party irrigation pumps.</div>
                            </div>
                </div>


            
            </div>
            
        </div>
    )


    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>

            
            
            
            <div style={{padding:'10px',display:'flex',flexDirection:'column',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>

                
                
                <div style={{border:'1px solid #F1F1F1',backgroundColor:'#F4F4F4',marginBottom:'5px',display:'flex',flexDirection:'column',borderRadius:'1px'}}>

                
                <div>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start',paddingRight:'15px',padding:'10px 0px 0px 20px'}}>
                        <div style={{fontSize:'31px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                            Robust Design
                        </div>
                        <div style={{fontSize:'13px',fontFamily:"'Roboto', sans-serif",fontWeight:500,padding:'5px 0px 10px 30px',opacity:0.7}}>
                            Carefully built out of simplicity.
                        </div>
                    </div>
                </div>



                <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,overflow:'hidden'}}>
                    <img src={boxFrontFacingPowerBtn} style={{width:'120%'}}/>
                </div>

                
                <div style={{display:'flex',flexDirection:'row',backgroundColor:'#f4f4f4'}}>
                    
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,margin:'5px 2.5px 5px 5px',backgroundColor:'#FFFFFF',borderRadius:2}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',overflow:'hidden'}}>
                            <img src={boxSided} style={{width:'100%',}}/>
                        </div>
                    </div>


                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,margin:'5px 5px 5px 2.5px',backgroundColor:'#FFFFFF',borderRadius:2}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                            <img src={boxBackSidedNew} style={{width:'100%',}}/>
                        </div>
                    </div>
                        
                </div>

                </div>
 


            </div>
        </div>
    )

}




export function NewStageMobile(){
    return(
        <div style={{height:(screenHeight - 150),width:'100%',marginTop:0}} className="newStageMobileWrapper">
            <div style={{height:'100%',width:'100%',overflow:'hidden',position:'relative'}}>
               
                
                <div style={{backgroundColor:'rgba(0,0,0,0.05)',position:'absolute',top:0,left:0,right:0,bottom:0,zIndex:100}}>
                    {/* dark opacity guy */}
                </div>


                <div style={{height:'100%',width:'100%',overflow:'hidden',position:'relative',display:'flex',flexDirection:'column'}}>

                    <div style={{}}>
                        <img src={require('../../images/new_images/irridation2.jpg')} style={{width:'100%'}} className="resize_fit_center"/>
                    </div>

                    <div style={{zIndex:150,padding:'20px 0px 0px',display:'flex',flex:1}}>
                        
                        <div className="NewStageMobile_dec" style={{flex:1}}>
                        Integrated with agricultural systems such as precision farming and weather forecasting, to create a more comprehensive and effective approach to irrigation.
                        </div>
                    </div>

                </div>




            </div>
            
            

        </div>
    )
}




export function NewStagePc(){

    return(
        <div style={{backgroundColor:'skyblue',height:(screenHeight - 60),width:'100%',marginTop:0}}>
            <div style={{height:'100%',width:'100%',overflow:'hidden',position:'relative'}}>
               
                <div style={{}}>
                    <img src={require('../../images/new_images/irridation2.jpg')} style={{width:'100%'}}/>
                </div>
                
                <div style={{backgroundColor:'rgba(0,0,0,0.6)',position:'absolute',top:0,left:0,right:0,bottom:0,zIndex:100}}>
                    {/* dark opacity guy */}
                </div>

                <div style={{position:'absolute',top:0,left:0,right:0,zIndex:150,padding:'20px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    
                    <div className="NewStagePc_dec" style={{}}>
                        Integrated with agricultural systems such as precision farming and weather forecasting, to create a more comprehensive and effective approach to irrigation.
                    </div>
                </div>

            </div>
            
            

        </div>
    )
    
}




export function TestimonyFuncMobile(){
    return(
        <div className="testimony_wrapper_m" style={{}}>

            <div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',padding:'30px 10px 30px'}}>
                    <div style={{fontSize:'27px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                        What Our Customers Say
                    </div>
                </div>
            </div>


            
            {/* bubble starts */}
            <div style={{}} className='testimony_wrapper'>
                <div className='bubble_profile_carrier'>
                    <div className='bubble_profile_wrapper'>
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                        </div>
                    </div>
                    <div className='bubble_profile_name'>Halidu - Baruten, Kwara State</div>
                </div>

                <div style={{}} className='bubble_mgs_wrapper'>

                "As a farmer, I have been using Powerbox Irrigation system on my land for the past year and I have been extremely happy with the results. The system is easy to use and has helped me to conserve water and improve health and yields of my crops.""As a farmer, I have been using Powerbox Irrigation system on my land for the past year and I have been extremely happy with the results. The system is easy to use and has helped me to conserve water and improve health and yields of my crops."
                </div>
            </div>
            {/* bubble ends */}


            {/* bubble starts */}
            <div style={{}} className='testimony_wrapper'>
                <div className='bubble_profile_carrier'>
                    <div className='bubble_profile_wrapper'>
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                        </div>
                    </div>
                    <div className='bubble_profile_name'>John - Guma, Benue State</div>
                </div>

                <div style={{}} className='bubble_mgs_wrapper'>
                "I have noticed a significant increase in my crop yields and a corresponding increase in my profits. I would highly recommend this irrigation system to other farmers looking to improve their  operations."
                </div>
            </div>
            {/* bubble ends */}


            {/* bubble starts */}
            <div style={{}} className='testimony_wrapper'>
                <div className='bubble_profile_carrier'>
                    <div className='bubble_profile_wrapper'>
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                        </div>
                    </div>
                    <div className='bubble_profile_name'>Oyindamola - Ogere, Ogun State</div>
                </div>

                <div style={{}} className='bubble_mgs_wrapper'>
                "Overall, I am very satisfied with my decision to invest in Powerbox's irrigation system, and I highly recommend it to farmers. I have been able to precisely monitor and manage the amount of water applied to my crop, which has greatly improved the efficiency and productivity of my farm"
                </div>
            </div>
            {/* bubble ends */}





        </div>
    )
}








export function TestimonyFuncPc(){
    return(
        <div className="testimony_wrapper_pc" style={{}}>
            <div style={{margin:'0px auto 0px'}}>

                    <div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',padding:'30px 10px 30px'}}>
                            <div style={{fontSize:'27px',fontFamily:"'Roboto', sans-serif",fontWeight:300,padding:'5px 0px 0px'}}>
                                What Our Customers Say
                            </div>
                        </div>
                    </div>

                    <div style={{display:'flex',flexDirection:'row',paddingTop:'20px',paddingBottom:'20px'}}>
                            
                            {/* bubble starts */}
                            <div style={{}} className='testimony_wrapper_bubble_pc'>
                                <div className='bubble_profile_carrier'>
                                    <div className='bubble_profile_wrapper'>
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                                        </div>
                                    </div>
                                    <div className='bubble_profile_name'>Halidu - Baruten, Kwara State</div>
                                </div>

                                <div style={{}} className='bubble_mgs_wrapper'>
                                "As a farmer, I have been using Powerbox Irrigation system on my land for the past year and I have been extremely happy with the results. The system is easy to use and has helped me to conserve water and improve health and yields of my crops."
                                </div>
                            </div>
                            {/* bubble ends */}


                            {/* bubble starts */}
                            <div style={{}} className='testimony_wrapper_bubble_pc'>
                                <div className='bubble_profile_carrier'>
                                    <div className='bubble_profile_wrapper'>
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                                        </div>
                                    </div>
                                    <div className='bubble_profile_name'>John - Guma, Benue State</div>
                                </div>

                                <div style={{}} className='bubble_mgs_wrapper'>
                                "I have noticed a significant increase in my crop yields and a corresponding increase in my profits. I would highly recommend this irrigation system to other farmers looking to improve their  operations."
                                </div>
                            </div>
                            {/* bubble ends */}


                            {/* bubble starts */}
                            <div style={{}} className='testimony_wrapper_bubble_pc'>
                                <div className='bubble_profile_carrier'>
                                    <div className='bubble_profile_wrapper'>
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={require('../../images/new_images/chat.png')} style={{width:25,height:25}}/>
                                        </div>
                                    </div>
                                    <div className='bubble_profile_name'>Oyindamola - Ogere, Ogun State</div>
                                </div>

                                <div style={{}} className='bubble_mgs_wrapper'>
                                "Overall, I am very satisfied with my decision to invest in Powerbox's irrigation system, and I highly recommend it to farmers. I have been able to precisely monitor and manage the amount of water applied to my crop, which has greatly improved the efficiency and productivity of my farm"
                                </div>
                            </div>
                            {/* bubble ends */}

                    </div>




            </div>
        </div>
    )
}






export function AwardFuncPc(){
    return(
        <div className="award_wrapper_pc" style={{}}>
            <div style={{margin:'0px auto 0px'}}>

                    <div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'30px 10px 30px'}}>
                            <div style={{fontSize:'55px',fontFamily:"'Roboto', sans-serif",fontWeight:100,padding:'5px 100px 0px',lineHeight:'70px',color:'#FFFFFF'}}>
                                The Most Innovative African clean Agri-tech Company 2022 
                            </div>
                        </div>
                    </div>




                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:40,marginBottom:80}}>
                       
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginRight:'100px'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={lhgp_Logo} style={{width:'110px',height:'110px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100'}}>
                                Lion's Head Global Partners (UK)
                            </div>

                        </div>

                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:'100px'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={afritech_Logo} style={{width:'110px',height:'110px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100'}}>
                            AfriTech XYZ 
                            </div>

                        </div>

                    </div>

                   




            </div>
        </div>
    )
}



export function AwardFuncMobile(){
    return(
        <div className="award_wrapper_pc" style={{}}>
            <div style={{width:'100%'}}>

                    <div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'20px 10px 30px'}}>
                            <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:300,color:'#FFFFFF'}}>
                                The Most Innovative African Clean-energy Company 2022 
                            </div>
                        </div>
                    </div>




                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:20,marginBottom:40}}>
                       
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={lhgp_Logo} style={{width:'70px',height:'70px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100',fontSize:12,maxWidth:150,textAlign:'center'}}>
                                Lion's Head Global Partners (UK)
                            </div>

                        </div>

                        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div style={{color:'#FFFFFF'}}>
                                <img src={afritech_Logo} style={{width:'70px',height:'70px',borderRadius:'150px',overflow:'hidden'}}/>
                            </div>

                            <div style={{color:'#FFFFFF',paddingTop:10,fontWeight:'100',fontSize:12,maxWidth:150,textAlign:'center'}}>
                            AfriTech XYZ 
                            </div>

                        </div>

                    </div>

                   




            </div>
        </div>
    )
}






export function FourthStagePc(){












    const [showContact, setShowContact] = useState(false);

    function processPurchase(){
        setShowContact(true);
    }


    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
            <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>

            <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#F4F4F4',flex:1}}>
                
                <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'30px'}}>
                   
                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Designed and produced in Nigeria</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Easy farm installation in less than 30 minutes</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Portable and Movable</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Long lasting Energy Storage</div>
                    </div>

                    <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                        <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>24/7 Customer Service</div>
                    </div>

                </div>





                
                <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <div style={{fontSize:'50px',fontFamily:"'Roboto', sans-serif",fontWeight:600}}>Powerbox Irrigation</div>

                    {(!showContact)?
                    <CSSTransitionGroup
                    transitionName="fadeout"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionEnter={false}
                    transitionLeave={true}
                    >
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div style={{fontSize:'17px',fontFamily:"'Roboto', sans-serif",fontWeight:300}}>Solar Panels included</div>
                            {/* <div style={{fontSize:'40px',fontFamily:"'Roboto', sans-serif",fontWeight:400,padding:'10px 0px 0px',display:'flex',flexDirection:'row'}}>
                                <div style={{marginBottom:'0px'}}>
                                    <div style={{marginBottom:'10px'}}>
                                        <Icons icon="naira" size="37" color="#000000"/>
                                    </div>
                                </div>
                                <div style={{}}>150,000</div>
                            </div> */}
                            <div
                                onClick={processPurchase} 
                                style={{padding:'15px 50px 12px',backgroundColor:'#FFFFFF',borderRadius:'5px',cursor:'pointer',marginTop:20}} className="buynowBtn">Buy Now</div>
                        </div>
                    </CSSTransitionGroup>
                    :undefined}


                   {(showContact)?     
                    <CSSTransitionGroup
                        transitionName="fade"
                        transitionAppear={true}
                        transitionAppearTimeout={1300}
                        transitionEnter={false}
                        transitionLeave={false}
                        >
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'15px'}}>
                            <div style={{fontSize:'16px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>Chat with us on whatsapp </div>
                            <div style={{fontSize:'35px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>09070811425</div>
                        </div>
                    </CSSTransitionGroup>
                    :undefined}

                    



                </div>




            </div>
        </div>
            
        </div>
    )}





    export function FourthStageMobile(){

        const [showContact, setShowContact] = useState(false);
    
        function processPurchase(){
            setShowContact(true);
        }
    
    
        return(
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
                <div style={{padding:'10px',display:'flex',flexDirection:'row',backgroundColor:'#FFFFFF',maxWidth:'1600px',flex:1}}>
    
                <div style={{padding:'10px',display:'flex',flexDirection:'column',backgroundColor:'#F4F4F4',flex:1}}>
                    
                    <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'30px'}}>
                       
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Designed and produced in Nigeria</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Easy farm installation in less than 30 minutes</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Portable and Movable</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>Long lasting Energy Storage</div>
                        </div>
    
                        <div style={{padding:'0px 0px 22px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <div style={{paddingRight:'10px'}}><Icons icon="ok" size="25" color="#00972D"/></div>
                            <div style={{fontSize:'15px',fontFamily:"'Roboto', sans-serif",fontWeight:500,}}>24/7 Customer Service</div>
                        </div>
    
                    </div>
    
    
    
    
    
                    
                    <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'20px'}}>
                        <div style={{fontSize:'33px',fontFamily:"'Roboto', sans-serif",fontWeight:600}}>Powerbox Irrigation</div>
    
                        {(!showContact)?
                        <CSSTransitionGroup
                        transitionName="fadeout"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnter={false}
                        transitionLeave={true}
                        >
                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <div style={{fontSize:'14px',fontFamily:"'Roboto', sans-serif",fontWeight:300}}>Solar Panels included</div>
                               
                                <div
                                    onClick={processPurchase} 
                                    style={{padding:'15px 50px 12px',backgroundColor:'#FFFFFF',borderRadius:'5px',cursor:'pointer',marginTop:20}} className="buynowBtn">Buy Now</div>

                            </div>
                        </CSSTransitionGroup>
                        :undefined}
    
    
                       {(showContact)?     
                        <CSSTransitionGroup
                            transitionName="fade"
                            transitionAppear={true}
                            transitionAppearTimeout={1300}
                            transitionEnter={false}
                            transitionLeave={false}
                            >
                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'15px'}}>
                                <div style={{fontSize:'16px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>Chat with us on whatsapp </div>
                                <div style={{fontSize:'35px',fontFamily:"'Roboto', sans-serif",fontWeight:400,}}>09070811425</div>
                            </div>
                        </CSSTransitionGroup>
                        :undefined}
    
                        
    
    
    
                    </div>
    
    
    
    
                </div>
            </div>
                
            </div>
        )}






    export function FifthStageMobile(){
        return(
            <div className="footer_wrapper">

                <div style={{}} className='footer_logo_holder'>
                    <div style={{}} className='footer_logo_carrier'>
                        <Icons icon="logo" size="160" color="#399947"/>
                    </div>
                </div>


                <div style={{padding:'15px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Our Products</div>
                    <div style={{}} className='footer_h2'>Powerbox Irrigation</div>
                    <div style={{}} className='footer_h2'>Powerbox Pumps and sensors</div>
                </div>


                {/* <div style={{padding:'0px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Help</div>
                    <div style={{}} className='footer_h2'>FAQs</div>
                    <div style={{}} className='footer_h2'>Contact Us</div>
                </div> */}

                <div style={{padding:'0px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Twitter</div>
                    <div style={{}} className='footer_h2'>@powerboxEnergy</div>
                </div>

                <div style={{padding:'0px 20px 10px'}}>
                    <div style={{}} className='footer_h1'>Email</div>
                    <div style={{}} className='footer_h2'>Support@powerbox.com.ng</div>
                </div>

                <div style={{padding:'0px 20px 20px'}}>
                    <div style={{}} className='footer_h1'>Legal</div>
                    <div style={{}} className='footer_h2'>Privacy Policy</div>
                </div>


                <div className='last_footer_wrapper'>
                    The Powerbox logo and technologies are trademark properties of Powerbox Energy Systems Limited and its regional subsidiaries. 
                    <div style={{padding:'0px 20px 0px'}}>
                        &#169; 2021 Powerbox Energy Systems Limited. All Rights Reserved.
                    </div> 
                </div>
                


                {/* the footer of the pagess */}
            </div>  
        )  
    }






    export function FifthStagePc(){
        return(
            <div className="footer_wrapper">


                <div style={{maxWidth:'1600px',margin:'30px auto 30px',display:'flex',flexDirection:'row',padding:'20px 15px 50px'}}>




                    <div style={{flex:1.1,alignItems:'center',display:'flex',flexDirection:'column'}}>
                        <div style={{height:'110px',overflow:'hidden',marginTop:'-65px'}} >
                            <Icons icon="logo2" size="160" color="#399947"/>
                        </div>
                    </div>


                    <div style={{padding:'0px 20px 10px',flex:1}}>
                        <div style={{}} className='footer_h1'>Our Products</div>
                        <div style={{}} className='footer_h2'>Powerbox Irrigation</div>
                        <div style={{}} className='footer_h2'>Powerbox Pumps and Sensors</div>
                    </div>


                    <div style={{padding:'0px 20px 10px',flex:1}}>
                        <div style={{}} className='footer_h1'>Twitter</div>
                        <div style={{}} className='footer_h2'>@powerboxEnergy</div>
                    </div>

                    <div style={{padding:'0px 20px 10px',flex:1}}>
                        <div style={{}} className='footer_h1'>Email</div>
                        <div style={{}} className='footer_h2'>Support@powerbox.com.ng</div>
                    </div>

                    <div style={{padding:'0px 20px 20px',flex:1}}>
                        <div style={{}} className='footer_h1'>Legal</div>
                        <div style={{}} className='footer_h2'>Privacy Policy</div>
                    </div>


                    


                    
                </div>

                    <div className='last_footer_wrapper_pc'>
                    The Powerbox logo and technologies are trademark properties of Powerbox Energy Systems Limited and its regional subsidiaries. 
                        <div style={{padding:'0px 20px 0px'}}>
                            &#169; 2023 Powerbox Energy Systems Limited. All Rights Reserved.
                        </div> 
                    </div>

                
            </div>  
        )  
    }









export default function Home(){

    useEffect(() => {
        ReactGA.pageview(window.location);
    },[]);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 800px)'
      })


    return(
    <div>
        <Header/>
        <TopStage/>

        

        {(isDesktopOrLaptop)?
        <SecondStagePc/>:
        <SecondStageMobile/>}

        {(isDesktopOrLaptop)?
        <TestimonyFuncPc/>:
        <TestimonyFuncMobile/>}

        {(isDesktopOrLaptop)?
        <NewStagePc/>:
        <NewStageMobile/>}
        
       
        {(isDesktopOrLaptop)?
        <ThirdStagePc/>:
        <ThirdStageMobile/>}

        {/* {(isDesktopOrLaptop)?
        <TestimonyFuncPc/>:
        <TestimonyFuncMobile/>} */}

        {(isDesktopOrLaptop)?
        <AwardFuncPc/>:
        <AwardFuncMobile/>}




        {(isDesktopOrLaptop)?
        <FourthStagePc />:
        <FourthStageMobile/>
        }

        {(isDesktopOrLaptop)?
        <FifthStagePc/>:
        <FifthStageMobile/>
        }
    </div>)
}